
import { defineComponent } from 'vue'
import MdiAlert from '@/components/base/icons/mdi/MdiAlert.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    MdiAlert,
  },

  setup() {
    const { t } = useI18n()

    return {
      t,
    }
  },
})
