<template>
  <main class="flex items-center justify-center h-screen px-4 bg-gray-100">
    <div
      class="flex flex-col items-center justify-center sm:flex-row sm:items-center"
    >
      <p class="font-semibold text-red-500 text-9xl">{{ t('error') }}</p>
      <div class="sm:ml-4 text-center sm:text-left">
        <h1 class="flex justify-center sm:justify-start items-center">
          <mdi-alert class="text-red-500 mr-1" />
          <span class="text-xl font-medium text-gray-600 sm:text-2xl">
            {{ t('title') }}
          </span>
        </h1>
        <p class="text-base font-normal text-gray-600">
          {{ t('subtitle') }}
        </p>
        <p class="text-base font-normal text-gray-600">
          {{ t('description') }}

          <router-link class="text-blue-500 capitalize" to="/">{{
            t('home')
          }}</router-link>
        </p>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MdiAlert from '@/components/base/icons/mdi/MdiAlert.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    MdiAlert,
  },

  setup() {
    const { t } = useI18n()

    return {
      t,
    }
  },
})
</script>

<i18n lang="yml">
en:
  title: 'Oops! Page not found.'
  subtitle: The page you ara looking for was not found.
  description: Return to
  home: home
  error: '404'
ja:
  title: 'Oops! Page not found.'
  subtitle: The page you ara looking for was not found.
  description: Return to
  home: home
  error: '404'
</i18n>
